import "./PurchasePolicy.scss";

import { ChangeEvent, FormEvent, ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import BlobFee from "assets/icons/BlobFee/BlobFee.svg";
import amexImg from "assets/images/payment/amex.svg";
import discoverImg from "assets/images/payment/discover.svg";
import mastercardImg from "assets/images/payment/mastercard.svg";
import stripeImg from "assets/images/payment/stripe-blurple.svg";
import visaImg from "assets/images/payment/visa.svg";
import Button from "components/Button/Button";
import PaymentCardInput, { PaymentInputState } from "components/PaymentCardInput/PaymentCardInput";
import ScreenLoader from "components/ScreenLoader/ScreenLoader";
import Text from "components/Text/Text";
import TextInput from "components/TextInput/TextInput";
import { Card, Layout, useTranslation } from "modules";
import { RootState } from "store";
import { formatPaymentName } from "utils/formatPaymentName";

import classNames from "classnames";
import { every, noop } from "lodash-es";

interface IPurchasePolicy {
  adCards: ("free-look-period" | "trusted-payments")[];
  loadingData: boolean;
  submitting: boolean;
  analyticsId?: string;
  policyDocHref?: string;
  onSubmit(event: FormEvent<HTMLFormElement>): Promise<void>;
  renderCoverages(): ReactNode;
  renderLegalese(): ReactNode;
  onClickPolicyDoc?(): void;
  renderAcknowledgements?(): ReactNode | void;
}

function PurchasePolicy(props: IPurchasePolicy) {
  const {
    adCards,
    analyticsId,
    loadingData,
    onSubmit,
    renderAcknowledgements = noop,
    renderCoverages,
    renderLegalese,
    submitting,
  } = props;

  const { t } = useTranslation();

  const userName = useSelector((state: RootState) =>
    [state.profile.firstName, state.profile.lastName].join(" ")
  );

  const [cardholderName, setCardholderName] = useState(formatPaymentName(userName));
  const [cardState, setCardState] = useState<PaymentInputState>({});

  const handleCardholderNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = formatPaymentName(value);
    setCardholderName(name);
  };
  const handleCardInputChange = (state: PaymentInputState) => {
    setCardState(state);
  };

  const validations = {
    card: () => every([cardState.complete, !cardState.empty, !!!cardState.error]),
    name: () => every([!!cardholderName, cardholderName.length >= 3]),
  };

  const adCardContentByKey = {
    "free-look-period": {
      title: t("payment.marketing.guarantee.title", "10-DAY FREE LOOK PERIOD"),
      description: t(
        "payment.marketing.guarantee.info",
        "If you're not happy and cancel your policy within 10 days of payment you'll get a full refund."
      ),
      imgSrc: BlobFee,
    },
    "trusted-payments": {
      title: t("payment.marketing.trust.title", "TRUSTED PAYMENTS"),
      description: t(
        "payment.marketing.trust.info",
        "We partner with the best so you can rest assured knowing your payment info is safe and secure."
      ),
      imgSrc: stripeImg,
    },
  };
  const isFormReady = validations.card() && validations.name();

  useEffect(() => {
    setCardholderName(formatPaymentName(userName));
  }, [userName]);

  return (
    <Layout
      as="MessageLayout"
      backNav={{ hasBackNav: true, replaceExitInJourneyWithBack: false }}
      className="purchase-policy__container"
      content={{
        title: {
          tag: "h3",
          text: t("purchase_policy.header", "Last step!"),
        },
      }}
      fallback={<ScreenLoader />}
      renderFallback={loadingData}>
      <Text
        className="purchase-policy__info"
        tag="p3"
        text={t(
          "purchase_policy.subheader",
          "Simply add a valid credit or debit card, review your policy, and get covered instantly!"
        )}
      />

      <div className="purchase-policy__coverages">{renderCoverages()}</div>

      <form
        className={classNames("purchase-policy__form", { disabled: !!submitting })}
        onSubmit={onSubmit}>
        <div className="purchase-policy__inputs">
          <div className="purchase-policy__cc-entry-container">
            <TextInput
              dataTestId="purchase-policy__cc-entry-name"
              id="text"
              label={t("card.entry.name.placeholder", "Name on card")}
              onChange={handleCardholderNameChange}
              type="text"
              value={cardholderName}
            />

            <PaymentCardInput onChange={handleCardInputChange} />

            <div className="purchase-policy__cc-icons">
              <img src={amexImg} alt="American Express" />
              <img src={mastercardImg} alt="Mastercard" />
              <img src={visaImg} alt="Visa" />
              <img src={discoverImg} alt="Discover" />
            </div>
          </div>

          <div className="purchase-policy__legalese">
            {renderAcknowledgements()}

            <Text
              className="purchase-policy__legalese--header"
              tag="p6"
              text={t("purchase_policy.legalese.header", "By continuing, you agree to:")}
            />
            <Text className="purchase-policy__legalese--bullets" tag="p6">
              {renderLegalese()}
            </Text>

            <Button
              dataAnalyticsId={analyticsId}
              className="purchase-policy__cta"
              dataTestId="payment-cc__cta"
              disabled={!isFormReady || submitting}
              isLoading={submitting}
              text={t("purchase_policy.cta", "SUBMIT & PAY")}
              type="submit"
            />
          </div>
        </div>
      </form>

      <Card as="MultipleAdCard" content={{ items: adCards.map(key => adCardContentByKey[key]) }} />
    </Layout>
  );
}

export default PurchasePolicy;
