import { FlowTypes, IFlow, IFlowForm, ISO8601Date, OrNull } from "config";

/*
..................
~~~ INTERFACES ~~~
..................
*/

/* ------------------    DYNAMIC FORM    ------------------ */
export interface IDynamicForm {
  description: string;
  flow: Pick<IFlow, "slug"> | { slug: "" };
  id: string;
  items: DynamicFormItems[];
  layout: DynamicFormLayoutType;
  slug: string;
  groups?: OrNull<DynamicFormGroup[]>;
  leadingNavigationButton?: ButtonFormItem["content"];
}

export type DynamicFormGroup = ApiFormGroup | ComponentFormGroup;

export type DynamicFormItems =
  | ButtonFormItem
  | DecorationFormItem
  | CheckboxInputFormItem
  | DateInputFormItem
  | ImageFormItem
  | ListRowFormItem
  | MultiSelectInputFormItem
  | NumericInputFormItem
  | SingleSelectInputFormItem
  | SliderInputFormItem
  | TaxIdInputFormItem
  | TextInputFormItem
  | UnitizedInputFormItem
  | SpacerFormItem
  | TextFormItem;

export type DynamicFormInputItems = Exclude<
  DynamicFormItems,
  { type: keyof typeof EnumDynamicFormNonInputItemTypes }
>;

export interface IApiArgument {
  name: string;
  source: DynamicFormItem["key"] | string;
  sourceType: ArgumentSourceType;
  type: ArgumentType;
}

/* ------------------    FORM GROUP    ------------------ */
interface IDynamicFormGroup<T extends FormGroupType = FormGroupType> {
  detail: GroupDetailMap[T];
  type: FormGroupType;
}

export type ApiFormGroup = IDynamicFormGroup<"api">;

export type ComponentFormGroup = IDynamicFormGroup<"component">;

interface ApiGroupDetail {
  key: string;
  mutationBody: string;
  mutationName: string;
  mutationVariables: IApiArgument[];
}

interface ComponentGroupDetail {
  itemKeys: DynamicFormItem["key"][];
  replacementKey: DynamicFormItem["key"];
  type: GroupDetailComponentTypes;
}

type GroupDetailMap = {
  api: ApiGroupDetail;
  component: ComponentGroupDetail;
};

/* ------------------   FORM ITEMS   ------------------ */
interface DynamicFormItem<T extends DynamicFormItemType = DynamicFormItemType> {
  content: ItemContentMap[T];
  key: string;
  type: T;
}

export type ButtonFormItem = DynamicFormItem<"button">;
export type DecorationFormItem = DynamicFormItem<"decoration">;
export type CheckboxInputFormItem = DynamicFormItem<"inputCheckbox">;
export type DateInputFormItem = DynamicFormItem<"inputDate">;
export type ImageFormItem = DynamicFormItem<"image">;
export type ListRowFormItem = DynamicFormItem<"listRow">;
export type MultiSelectInputFormItem = DynamicFormItem<"inputMultiSelect">;
export type NumericInputFormItem = DynamicFormItem<"inputNumeric">;
export type SingleSelectInputFormItem = DynamicFormItem<"inputSingleSelect">;
export type SliderInputFormItem = DynamicFormItem<"inputSlider">;
export type TaxIdInputFormItem = DynamicFormItem<"inputTaxId">;
export type TextInputFormItem = DynamicFormItem<"inputText">;
export type UnitizedInputFormItem = DynamicFormItem<"inputUnitized">;
export type TextFormItem = DynamicFormItem<"text">;
export type SpacerFormItem = DynamicFormItem<"space">;

/* ------------------   FORM ITEM - STATIC CONTENT    ------------------ */
type ItemContentMap = {
  button: IButtonContent;
  decoration: IDecorationContent;
  image: IImageContent;
  inputCheckbox: ICheckboxInputContent;
  inputDate: IDateInputContent;
  inputMultiSelect: IMultiSelectInputContent;
  inputNumeric: INumericInputContent;
  inputSingleSelect: ISingleSelectInputContent;
  inputSlider: ISliderInputContent;
  inputTaxId: ITaxIdInputContent;
  inputText: ITextInputContent;
  inputUnitized: IUnitizedInputContent;
  listRow: IListRowContent;
  text: ITextContent;
  space: ISpacerContent;
};

interface IButtonContent {
  actions: IButtonAction[];
  fixed: boolean;
  primary: boolean;
  style: ButtonStyleType;
  text: string;
}

export interface IButtonAction {
  command: IButtonCommand;
  type: ButtonActionType;
}

interface IButtonCommand {
  arguments?: OrNull<IApiArgument[]>;
  destination: string;
}

interface IDecorationContent {
  slug: DecorationKeyType | string;
}

interface IImageContent {
  alignment: "centered" | "leading" | "trailing";
  name: string;
  fillColor?: OrNull<string>;
  height?: OrNull<number>;
  width?: OrNull<number>;
}

export interface IListRowColumnContent {
  actions?: OrNull<IButtonAction[]>;
  image?: OrNull<IImageContent>;
  texts?: OrNull<ITextContent[]>;
}

interface IListRowContent {
  showsDivider: boolean;
  verticalPadding: SpacerValueType;
  actions?: OrNull<IButtonAction[]>;
  leadingColumn?: OrNull<IListRowColumnContent>;
  trailingColumn?: OrNull<IListRowColumnContent>;
}

interface ISpacerContent {
  min: SpacerValueType;
  mode: SpacerModeType;
}

interface ITextContent {
  color: TextContentColorType;
  html: boolean;
  text: string;
  typography: TextContentTypographyType;
}

/* ------------------    FORM ITEM - INPUT CONTENT    ------------------ */
interface InputContentBase {
  disabled: boolean;
  label: string;
  required: boolean;
  id?: string;
}

export interface ICheckboxInputContent extends InputContentBase {
  text: string;
  acknowledgementGroupKeys?: string[];
  value?: OrNull<boolean>;
}

export interface IDateInputContent extends InputContentBase {
  hint?: string;
  max?: OrNull<ISO8601Date>;
  min?: OrNull<ISO8601Date>;
  value?: OrNull<ISO8601Date>;
}

export interface IMultiSelectInputContent extends InputContentBase {
  options: ISelectionOption[];
  value?: OrNull<string[]>;
}

export interface INumericInputContent extends InputContentBase {
  precision: number;
  currency?: boolean;
  hint?: string;
  max?: number;
  min?: number;
  unit?: string;
  value?: OrNull<number>;
}

interface ISelectionOption {
  label: string;
  value: string;
  key?: OrNull<string>;
}

export interface ISingleSelectInputContent extends InputContentBase {
  format: SingleSelectInputFormatType;
  options: ISelectionOption[];
  element?: OrNull<string>;
  hint?: string;
  value?: OrNull<string>;
}

export interface ISliderInputContent extends InputContentBase {
  textEditable: boolean;
  ticks: ISliderTick[];
  characterMax?: OrNull<number>;
  increment?: OrNull<number>;
  leftTextLabel?: OrNull<string>;
  rightTextLabel?: OrNull<string>;
  maxErrorMessage?: string;
  minErrorMessage?: string;
  value?: OrNull<number>;
  valueBase?: OrNull<number>;
}

interface ISliderTick {
  label: string;
  value: number;
}

export interface ITaxIdInputContent extends InputContentBase {
  encryptOutput: boolean;
  hint: string;
  value?: string;
}

export interface ITextInputContent extends InputContentBase {
  format: TextInputFormatType;
  characterMax?: OrNull<number>;
  element?: string;
  hint?: string;
  value?: OrNull<string>;
}

export interface IUnitizedInputContent extends InputContentBase {
  options: IUnitizedOption[];
  value?: OrNull<(string | undefined)[]>;
}

interface IUnitizedOption {
  label: string;
  min: number;
  max: number;
}

/*
..................
~~~~~ UNION TYPES ~~~~~
..................
*/

export type DynamicFormCriteriaFlowScope = "application" | "finance" | "policy_holder";
export type DynamicFormLayoutType = keyof typeof EnumDynamicFormLayouts;
export type DynamicFormItemType = keyof typeof EnumDynamicFormItemTypes;
type ArgumentSourceType = keyof typeof EnumArgumentSourceType;
type ArgumentType = keyof typeof EnumArgumentType;
type ButtonActionType = keyof typeof EnumButtonAction;
type ButtonStyleType = keyof typeof EnumButtonStyle;
type DecorationKeyType = keyof typeof EnumDecorationKey;
type FormGroupType = keyof typeof EnumFormGroup;
type GroupDetailComponentTypes = keyof typeof EnumGroupDetailComponent;
type SingleSelectInputFormatType = keyof typeof EnumSingleSelectInputFormat;
type SpacerModeType = keyof typeof EnumSpacerMode;
type SpacerValueType = keyof typeof EnumSpacerValue;
type TextContentColorType = keyof typeof EnumTextColor;
type TextContentTypographyType = keyof typeof EnumTextTypography;
type TextInputFormatType = keyof typeof EnumTextInputFormat;

/*
..................
~~~~~ ENUMS ~~~~~
..................
*/

enum EnumArgumentSourceType {
  input,
  static,
}

enum EnumArgumentType {
  boolean,
  float,
  int,
  string,
}

enum EnumButtonAction {
  api, // local
  link, // local/remote
  validation,
}

enum EnumButtonStyle {
  back,
  destructive,
  exit,
  hidden,
  primary,
  secondary,
  textLink,
}

enum EnumDecorationKey {
  "bene-delete-confirm-image",
  "privacy-insurance-application",
  "privacy-savings-application",
  "terminal-illness-rider",
  "thin_divider",
  "sms-transactional",
}

enum EnumDynamicFormItemTypes {
  button,
  decoration,
  image,
  inputCheckbox,
  inputDate,
  inputMultiSelect,
  inputNumeric,
  inputSingleSelect,
  inputSlider,
  inputTaxId,
  inputText,
  inputUnitized,
  listRow,
  space,
  text,
}

export enum EnumDynamicFormNonInputItemTypes {
  button = "button",
  decoration = "decoration",
  image = "image",
  listRow = "listRow",
  space = "space",
  text = "text",
}

enum EnumDynamicFormLayouts {
  confirmation,
  modal,
  myBeneficiaries,
  review,
  terminal,
  vertical,
}

enum EnumFormGroup {
  api,
  component,
}

enum EnumGroupDetailComponent {
  address,
  phone,
}

enum EnumSingleSelectInputFormat {
  blobs,
  dropdown,
  radio,
  rectangles,
}

enum EnumSpacerMode {
  fixed,
  flexible,
}

enum EnumSpacerValue {
  space0,
  space4,
  space8,
  space12,
  space16,
  space24,
  space32,
  space40,
  space48,
  space64,
  space80,
}

enum EnumTextColor {
  textBold,
  textError,
  textPrimary,
  textPrimaryInverse,
  textSecondary,
  textTertiary,
}

enum EnumTextInputFormat {
  multiLine,
  singleLine,
}

enum EnumTextTypography {
  d1,
  d2,
  h1,
  h2,
  h3,
  h4,
  h5,
  b1,
  b2,
  b3,
  b4,
  b5,
  c1,
  c2,
  c3,
  c4,
  c5,
  l1,
  l2,
  l3,
  l4,
  l5,
  l6,
  n1,
  n2,
  n3,
  n4,
  n5,
  n6,
}

/*
..................
~~~~~ PREDICATES ~~~~~
..................
*/

export function isFormDynamicForm(form: IFlowForm | IDynamicForm): form is IDynamicForm {
  return (form as IDynamicForm)?.items !== undefined;
}

export function isDFGroupComponent(formData: DynamicFormGroup): formData is ComponentFormGroup {
  const formDataTypeIsComponent = formData.type === "component";

  return formDataTypeIsComponent;
}

export function isDF3KSlug(formSlug: FlowTypes) {
  const df3kFormSlugs = [
    "account-profile",
    "aura-interview",
    "beneficiary",
    "cdd-form",
    "pre-verified-initial-data",
    "estimate-form",
    "estimate-v4",
    "insurance-profile",
    "savings-cdd",
    "term-life-extension",
    "onboarding-intro",
  ];

  return df3kFormSlugs.includes(formSlug);
}
